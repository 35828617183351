import React from "react"

import Nav from "../components/nav"
import Content from "../components/content"

const NotFoundPage = () =>
  <div>
    <Nav current="education"/>
    <Content>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn't exist...</p>
    </Content>
  </div>

export default NotFoundPage;
